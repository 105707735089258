import { dictionaryList } from "./language/index";
const defaultLanguage = "en";
const messages = dictionaryList[defaultLanguage].errors;

// Global regex
const noHtmlRegex = /<\/?[^>]+(>|$)/g;
const onlyAlphbetRegex = /^[a-zA-Z ]*$/;
const numberOnly = /^\d+$/;
var phoneRegex = /^[0-9]+$/;
var passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,12}$/;
const unitRegex = /^[0-9]+\.?[0-9]*$/;

const checkEmail = (value) => {
  if (
    !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    )
  ) {
    return true;
  } else if (
    value.includes('"') ||
    value.includes("'") ||
    value.includes(",") ||
    value.includes(" ")
  ) {
    return true;
  } else {
    return false;
  }
};

export const loginValidator = (values) => {
  let errors = {};
  console.log(values);

  if (!values.email) {
    errors.email = "Please Enter your email";
  } else if (checkEmail(values.email)) {
    errors.email = messages.email;
  }

  if (!values.password) {
    errors.password = "Please enter your password";
  }
  return errors;
};

export const forgotPassword = (values) => {
  let errors = {};
  console.log(values);

  if (!values.email) {
    errors.email = "Please Enter your email";
  } else if (checkEmail(values.email)) {
    errors.email = messages.email;
  }

  return errors;
};

export const resetPassword = (values) => {
  let errors = {};

  if (!values.new_password) {
    errors.new_password = "Please Enter new password";
  } else if (!passwordRegex.test(values.new_password)) {
    errors.new_password = messages.password;
  }

  if (!values.confirm_password) {
    errors.confirm_password = "Please Enter confirm password";
  } else if (values.new_password !== values.confirm_password) {
    errors.confirm_password = messages.passwordMatch;
  }

  return errors;
};

export const changedPasswordValidator = (values) => {
  let errors = {};
  console.log(values);

  if (!values.oldPassword) {
    errors.oldPassword = "Please enter your old password";
  }

  if (!values.newPassword) {
    errors.newPassword = "Please enter your new password";
  } else if (!passwordRegex.test(values.newPassword)) {
    errors.newPassword = messages.password;
  }

  if (!values.confirm_password) {
    errors.confirm_password = "Please re-enter same password";
  } else if (values.newPassword !== values.confirm_password) {
    errors.confirm_password = messages.passwordMatch;
  }

  return errors;
};

export const forgetValidator = (values) => {
  let errors = {};
  if (!values.mobile_number) {
    errors.phone = messages.invalid;
  } else if (!phoneRegex.test(values.mobile_number)) {
    errors.phone = messages.phone;
  } else if (values.mobile_number.length < 4) {
    errors.phone = messages.phone;
  }

  return errors;
};



export const completeProfileValidator = (values) => {
  let errors = {};
  // console.log(values);
  if (!values.username) {
    errors.username = messages.invalid;
  }
  if (!values.mobile_number) {
    errors.mobile_number = messages.invalid;
  } else if (!phoneRegex.test(values.mobile_number)) {
    errors.mobile_number = messages.phone;
  } else if (values.mobile_number.length < 4) {
    errors.mobile_number = messages.phone;
  }
  if (!values.email) {
    errors.email = messages.invalid;
  }
  if (!values.profile_image[0]) {
    errors.profile_image = "Upload profile image";
  }
  return errors;
};


export const createVoucherValidator = (values) => {
  let errors = {};
  if (!values.rewardIcon) {
    errors.rewardIcon = "Please upload the brand icon";
  }
  if (!values.brandName) {
    errors.brandName = "Please enter the brand name.";
  }
  if (!values.rewardName) {
    errors.rewardName = "Please enter the reward name.";
  }
  if (!values.voucherCode) {
    errors.voucherCode = "Please enter the voucher code";
  }
  if (!values.quantity) {
    errors.quantity = "Please enter the quantity";
  } else if (values.quantity < 0) {
    errors.quantity = "Quantity cannot be negative";
  }

  if (!values.price && values.price !== 0) {
    errors.price = "Please enter the price.";
  } else if (values.price < 0) {
    errors.price = "Price cannot be negative.";
  }
  if (!values.rewardExpireDate) {
    errors.rewardExpireDate = "Please select the reward expiry date.";
  }

  if (!values.description || values.description.length === 0) {
    errors.description = "Please enter the description";
  }
  if (!values.terms || values.terms.length === 0) {
    errors.terms = "Please enter the terms and condition";
  }
  if (!values.rewardAmount) {
    errors.rewardAmount = "Please enter the reward amount";
  } else if (values.rewardAmount < 0) {
    errors.rewardAmount = "Reward amount cannot be negative";
  }
  if (!values.redeemLink) {
    errors.redeemLink = "Please enter the redeem link.";
  }
  return errors;
}

export const createInAppValidator = (values) => {
  console.log(values, "--->>")
  let errors = {};
  if (!values.rewardIcon) {
    errors.rewardIcon = "Please upload the brand icon";
  }
  if (!values.inAppRewardType) {
    errors.inAppRewardType = "Please select in-app reward type.";
  }
  if (!values.quantity) {
    errors.quantity = "Please enter the quantity";
  } else if (values.quantity < 0) {
    errors.quantity = "Quantity cannot be negative";
  }
  // if (!values.price && values.price !== 0) {
  //   errors.price = "Please enter the price.";
  // } else if (values.price < 0) {
  //   errors.price = "Price cannot be negative.";
  // }  
  // if (!values.rewardName) {
  //   errors.rewardName = "Please enter the reward name.";
  // }
  if (!values.rewardExpireDate) {
    errors.rewardExpireDate = "Please select the reward expiry date.";
  }
  if (!values.rewardAmount) {
    errors.rewardAmount = "Please enter the reward amount";
  } else if (values.rewardAmount < 0) {
    errors.rewardAmount = "Reward amount cannot be negative";
  }
  if (!values.description || values.description.length === 0) {
    errors.description = "Please enter the description";
  }
  if (!values.terms || values.terms.length === 0) {
    errors.terms = "Please enter the terms and condition";
  }
  return errors;
}

export const createPremiumValidator = (values) => {
  console.log(values, "--->>")
  let errors = {};
  if (!values.rewardIcon) {
    errors.rewardIcon = "Please upload the brand icon";
  }
  if (!values.premiumRewardType) {
    errors.premiumRewardType = "Please select premium reward type.";
  }
  if (!values.quantity) {
    errors.quantity = "Please enter the quantity";
  } else if (values.quantity < 0) {
    errors.quantity = "Quantity cannot be negative";
  }
  if (!values.rewardName) {
    errors.rewardName = "Please enter the reward name.";
  }
  if (!values.price && values.price !== 0) {
    errors.price = "Please enter the price.";
  } else if (values.price < 0) {
    errors.price = "Price cannot be negative.";
  }
  if (!values.rewardExpireDate) {
    errors.rewardExpireDate = "Please select the reward expiry date.";
  }
  if (!values.rewardAmount) {
    errors.rewardAmount = "Please enter the reward amount";
  } else if (values.rewardAmount < 0) {
    errors.rewardAmount = "Reward amount cannot be negative";
  }
  if (!values.description || values.description.length === 0) {
    errors.description = "Please enter the description";
  }
  if (!values.terms || values.terms.length === 0) {
    errors.terms = "Please enter the terms and condition";
  }
  return errors;
}

export const createTripValidator = (values) => {
  console.log(values, "--->>")
  let errors = {};
  if (!values.rewardIcon) {
    errors.rewardIcon = "Please upload the brand icon";
  }
  if (!values.tripType) {
    errors.tripType = "Please select trip type";
  }
  if (!values.quantity) {
    errors.quantity = "Please enter the quantity";
  } else if (values.quantity < 0) {
    errors.quantity = "Quantity cannot be negative";
  }
  if (!values.rewardExpireDate) {
    errors.rewardExpireDate = "Please select the reward expiry date.";
  }
  if (!values.destination) {
    errors.destination = "Please enter the destination";
  }
  if (!values.pickupLocation) {
    errors.pickupLocation = "Please enter the pickup Location";
  }
  if (!values.rewardName) {
    errors.rewardName = "Please enter the reward name.";
  }
  if (!values.price && values.price !== 0) {
    errors.price = "Please enter the price.";
  } else if (values.price < 0) {
    errors.price = "Price cannot be negative.";
  }
  if (!values.validFrom) {
    errors.validFrom = "Please select the reward date";
  }
  if (!values.validTill) {
    errors.validTill = "Please select the reward date";
  }
  if (!values.rewardAmount) {
    errors.rewardAmount = "Please enter the reward amount";
  } else if (values.rewardAmount < 0) {
    errors.rewardAmount = "Reward amount cannot be negative";
  }
  if (!values.description || values.description.length === 0) {
    errors.description = "Please enter the description";
  }
  if (!values.terms || values.terms.length === 0) {
    errors.terms = "Please enter the terms and condition";
  }
  return errors;
}

export const createAuditValidator = (values) => {
  console.log(values, "--->>")
  let errors = {};
  if (!values.rewardIcon) {
    errors.rewardIcon = "Please upload the brand icon";
  }
  if (!values.quantity) {
    errors.quantity = "Please enter the quantity";
  } else if (values.quantity < 0) {
    errors.quantity = "Quantity cannot be negative";
  }
  if (!values.rewardName) {
    errors.rewardName = "Please enter the reward name.";
  }
  if (!values.rewardExpireDate) {
    errors.rewardExpireDate = "Please select the reward expiry date.";
  }
  if (!values.price && values.price !== 0) {
    errors.price = "Please enter the price.";
  } else if (values.price < 0) {
    errors.price = "Price cannot be negative.";
  }
  if (!values.tokenName) {
    errors.tokenName = "Please enter tokenName";
  }
  if (!values.rewardAmount) {
    errors.rewardAmount = "Please enter the reward amount";
  } else if (values.rewardAmount < 0) {
    errors.rewardAmount = "Reward amount cannot be negative";
  }
  if (!values.description || values.description.length === 0) {
    errors.description = "Please enter the description";
  }
  if (!values.terms || values.terms.length === 0) {
    errors.terms = "Please enter the terms and condition";
  }
  return errors;
}